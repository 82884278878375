<template>
<!-- 运动员-运动员数量排行  柱状图 -->
	<div>
		<div class="container darkBack">
			<div class="whiteFont font20">运动员数量排行</div>
			<div id="sportNumRanking"></div>
		</div>
	</div>
</template>

<script>
import {getAjax, timestamp} from "/src/assets/js/websocket";
	export default {
		data() {
			return{
				rankings:{
					site:['新昌县', '诸暨市', '柯桥区', '越城区', '上虞区','嵊州市'],
					data:[]    // 数据
				}
			}
		},
		methods:{
			// 绘制运动员数量排行图
			drawSportNumRanking(number){
				let that = this;
				// let myChart = this.$echarts.init(document.getElementById("sportNumRanking"));
				let myChart = this.$echarts.getInstanceByDom(document.getElementById("sportNumRanking"))
				if (myChart == null) { // 如果不存在，就进行初始化
					myChart = this.$echarts.init(document.getElementById("sportNumRanking"));
				}
				
				let option = {
					xAxis: {
						max: 'dataMax',
						// 不显示网格线
						splitLine:{
							show: true,
							lineStyle:{
								color:'#2D94D033',
							}
						},
						// 不显示刻度值
						axisLabel:{show:false}
					},
					grid: {         // 间距
						left: '6%',  
						right: '12%', 
						top:'8%', 
						bottom: '6%',  
						containLabel: true,
					},
					yAxis: {
						type: 'category',
						data: number.site,
						inverse: true,
						animationDuration: 300,
						animationDurationUpdate: 300,
						//y轴显示及颜色
						axisLine:{
							show:true,
							lineStyle:{
								color:'#2D94D0',
							}
						},
						// 刻度不显示
						axisTick:{show:false},
						// y轴字体样式
						axisLabel:{
							color:'#7EA7E2',
							fontSize:'0.14rem'
						},
					},
					series: [{
						// 实时排序
						realtimeSort: true,
						type: 'bar',
						data: number.data,
						// 柱子宽度
						barWidth:'30%',
						// 柱子间距
						barCategoryGap:10,
						// 数据显示
						label: {
							show: true,
							position: 'top',
							color:"#F1F1F1",
							valueAnimation: true,
                            fontSize:'0.14rem',
						},
						itemStyle: {
							normal: {
								color: this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
									offset: 0,
									color: "#2D94D0" // 0% 处的颜色
								}, {
									offset: 1,
									color: "#3AE6CE" // 100% 处的颜色
								}], false)
							}
						},
					}],
					animationDuration: 0,
					animationDurationUpdate: 3000,
					animationEasing: 'linear',
					animationEasingUpdate: 'linear'
				};
				
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);
				// 图表自动缩放
				window.addEventListener("resize",function(){
				  myChart.resize();
				});
			},
			// 获取数据
			getData(){
				let that = this;
				var data = {
                        token: window.getToken(),
                        app_id: window.appId,
                        time: timestamp(),
                    }
                    getAjax({
                        url: "/map/screen/getHomeSportsman",
                        method:"GET",
                        data:data,
                        success:function(res){
                            // console.log(res)
							that.rankings = {
								site:[],
								data:[] 
							}
							res.data.area_list.forEach((item,index) => {
								that.rankings.site.push(item.name);
								that.rankings.data.push(item.num);
							});
							that.drawSportNumRanking(that.rankings);
                        },
                    })
			}
		},
		mounted() {
			this.getData();
			// this.drawSportNumRanking(this.rankings);
		}
	}
</script>

<style scoped>
	.container{
		width: 5.96rem;
		height: 4.74rem;
		position: relative;
		padding: 0.2rem;
	}
	#sportNumRanking{
		width: 5.80rem;
		height: 3.80rem;
		/* border:1px solid red; */
		margin-left:-0.2rem;
	}
</style>